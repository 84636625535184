import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { Box, useTheme } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import { getLabelColor } from '../../shared/utils/layout';
import { PROFICIENCY_LEVELS } from '../../constants';

const HeatMapCell = ({
  data,
  flip,
  label,
  showHeader,
  showContributors,
  ...props
}) => {
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const textColor = isDarkTheme
    ? theme?.palette?.typography?.primary
    : 'rgba(0, 0, 0, 0.54)';

  return (
    <Box
      className="flex items-start"
      style={{ flexDirection: flip ? 'row-reverse' : 'row' }}
    >
      <Box height={data?.length * 40} width="200px">
        <ResponsiveHeatMap
          data={data}
          cellComponent={({ cell, ...cellProps }) => {
            const cellColor = Color(cell.color);
            return (
              <g
                fillOpacity={cell.opacity}
                onMouseEnter={cellProps.onMouseEnter?.(cell)}
                onMouseLeave={cellProps.onMouseLeave?.(cell)}
                onMouseMove={cellProps.onMouseMove?.(cell)}
                transform={`translate(${cell.x} ${cell.y})`}
              >
                <rect
                  fill={
                    cell?.data?.y > 0 ? cellColor.darken(0.1) : 'transparent'
                  }
                  height={cell.height}
                  transform={`translate(${-0.5 * cell.width} ${
                    -0.5 * cell.height
                  })`}
                  width={cell.width}
                  stroke="rgba(227, 230, 236, 1)" // Border color
                  strokeWidth="1" // Border width
                  rx="1" // Border radius for x-axis
                  ry="1" // Border radius for y-axis
                />
                <text
                  dominantBaseline="central"
                  fill={getLabelColor(cell.color)}
                  textAnchor="middle"
                  fontSize="8"
                  fontFamily="Montserrat"
                >
                  {cell?.data?.yLabel}
                </text>
              </g>
            );
          }}
          colors={{
            type: 'sequential',
            scheme: 'blue_purple',
            minValue: 0,
            maxValue: 50,
          }}
          axisTop={
            showHeader
              ? {
                  tickSize: 0,
                  tickPadding: 20,
                  tickRotation: 0,
                }
              : null
          }
          axisLeft={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
          }}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: textColor,
                },
              },
            },
          }}
          axisRight={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
          }}
          xInnerPadding={0.21}
          yInnerPadding={0.21}
          borderRadius={1}
          borderColor="#E3E6EC"
          borderWidth={1}
          emptyColor="white"
          hoverTarget="cell"
          tooltip={({ cell }) => (
            <div className="py-2 px-4 text-xs bg-opacity-80 bg-gray-700 border border-gray-300 rounded-md my-1 text-white">
              <div>
                <strong>Skill: </strong>
                {cell.data.yLabelName}
              </div>
              <div>
                <strong>Proficiency: </strong>
                {`Level ${cell.data.x} (${PROFICIENCY_LEVELS[cell.data.x]})`}
              </div>
              <div>
                <strong>
                  {showContributors && cell.data.yContributors?.length > 0
                    ? 'Solve Contributors:'
                    : cell.data.yType === 'solves'
                    ? 'Total Solved Challenges: '
                    : ''}
                </strong>
                {showContributors && (
                  <div className="w-60">
                    {cell.data.yContributors?.map((c) => (
                      <li key={c} className="ml-2">
                        {c}
                      </li>
                    ))}
                  </div>
                )}
                {!showContributors && (cell.data.yLabel || 0)}
              </div>
              <div>
                <strong>
                  {`Last ${
                    cell.data.yType === 'solves' ? 'Solve' : 'Attempt'
                  }: `}
                </strong>
                {cell.data.yLabelLastSolve}
              </div>
            </div>
          )}
          forceSquare
          {...props}
        />
      </Box>
      <Box
        className="py-1 px-2 rounded"
        style={{
          backgroundColor: '#E3E6EC',
          transform: flip ? 'rotate(270deg)' : 'rotate(90deg)',
          marginTop: '5.5rem',
        }}
      >
        <FdTypography
          variant="captiontext2"
          style={{ color: isDarkTheme ? '#000' : 'rgba(0, 0, 0, 0.87)' }}
        >
          {label}
        </FdTypography>
      </Box>
    </Box>
  );
};

HeatMapCell.defaultProps = {
  showContributors: false,
};

HeatMapCell.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flip: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showContributors: PropTypes.bool,
};

export default HeatMapCell;
