import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, subMonths, format } from 'date-fns';
import { gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  useQueryRecursive,
  useQueriesRecursive,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import HeatMapCell from './HeatMapCell';
import {
  listSkills,
  listTaskAttemptAggregatesByUserId,
} from '../../graphql/queries';
import { getArrayByLength } from '../../shared/utils/objectUtils';

const SPECIALTY_SORT = {
  Intelligence: { order: 1, paddingTop: 28 },
  Penetration: { order: 2, paddingTop: 20 },
  Protection: { order: 3, paddingTop: 2 },
  Detection: { order: 4, paddingTop: 2 },
  Engineering: { order: 5, paddingTop: 10 },
  Investigation: { order: 6, paddingTop: 2 },
};

const degradationRate = {
  1: 2,
  2: 3,
  3: 5,
  4: 8,
  5: 13,
};

const CyberBrain = ({ allMemberIds, timeLine, size, cortexMemberList }) => {
  const { data: listSkillsData, loading: listSkillsLoading } =
    useQueryRecursive(gql(listSkills));

  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueriesRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      limit: 1000,
    },
    recurringVariables: allMemberIds?.map((m) => ({
      userId: m,
    })),
  });

  const specialties = _(listSkillsData?.listSkills?.items || [])
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items,
    }))
    .value();

  const difficultyLevels = getArrayByLength(5, (__, i) => i + 1);
  const cortexUserIds = cortexMemberList
    ?.filter((m) => m.checked)
    ?.map((cm) => cm?.id);

  const listTaskAttemptAggregates =
    listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId?.items?.filter(
      (u) => cortexUserIds?.includes(u?.userId),
    ) || [];

  const attemptsData = listTaskAttemptAggregates?.filter(
    (la) => la?.task?.skills?.items?.length > 0,
  );

  const attemptsDataUnique = _.uniqWith(
    attemptsData,
    (a, b) => a?.taskId === b?.taskId && a?.userId === b?.userId,
  );
  const attemptsDataTimeLineBased = timeLine
    ? attemptsDataUnique?.filter((a) =>
        isWithinInterval(new Date(a?.updatedAt), {
          start: new Date(timeLine?.startDate),
          end: new Date(timeLine?.endDate),
        }),
      )
    : attemptsDataUnique;
  const onlySolves = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'COMPLETED',
  );

  // logic for level solves/effort
  // Ln = All solves/effort in Sum (Ln.. + L5)
  const getLevelPoints = (_level, _attempts) => {
    const levelEndDate2 = new Date();
    const levelStartDate2 = subMonths(levelEndDate2, degradationRate[_level]);

    return (
      _attempts?.filter(
        (a) =>
          a?.task?.difficulty === _level &&
          isWithinInterval(new Date(a?.updatedAt), {
            start: levelStartDate2,
            end: levelEndDate2,
          }),
      )?.length || 0
    );
  };

  const getDegradationScore = (_level, _attempts) => {
    let finalScore = 0;
    for (let index = _level; index <= 5; index += 1) {
      const levelEndDate = new Date();
      const levelStartDate = subMonths(levelEndDate, degradationRate[index]);

      const scoreInTimeFrame =
        _attempts?.filter(
          (a) =>
            a?.task?.difficulty === index &&
            isWithinInterval(new Date(a?.updatedAt), {
              start: levelStartDate,
              end: levelEndDate,
            }),
        )?.length || 0;

      finalScore += scoreInTimeFrame;
    }

    return finalScore > 0 ? finalScore : 0;
  };

  const specialtiesFormatted =
    specialties
      ?.sort(
        (a, b) =>
          SPECIALTY_SORT[a.specialtyName]?.order -
          SPECIALTY_SORT[b.specialtyName]?.order,
      )
      ?.map((specialty, idx) => {
        const flip = (idx + 1) % 2 === 1;
        const showHeader = idx + 1 < 3;
        const levels = flip
          ? [...difficultyLevels].reverse()
          : difficultyLevels;

        const dataSorted = specialty?.items?.map((skill) => {
          const onlySolvesUniqueByTaskId = _.uniqBy(onlySolves, 'taskId');
          const attempts =
            onlySolvesUniqueByTaskId?.filter((a) =>
              a?.task.skills.items?.some((s) =>
                s?.skill?.alias?.includes(skill?.alias),
              ),
            ) || [];
          const lastSolveDate = _.maxBy(
            attempts,
            (item) => new Date(item.updatedAt),
          )?.updatedAt;
          const lastSolveDateFormatted = lastSolveDate
            ? format(new Date(lastSolveDate), 'dd/MM/yyyy')
            : 'N/A';

          return {
            id: skill?.alias,
            data: [
              ...levels?.map((l) => {
                const levelPoints = getLevelPoints(l, attempts) || undefined;
                const contributors = _(onlySolves) // contributors shows all user solves
                  .groupBy('userId')
                  .map((items, userId) => {
                    const userName =
                      cortexMemberList?.find((u) => u?.id === userId)?.name ||
                      'Unknown';
                    const count = items?.length || 0;
                    return `${userName} (${count} Solve${
                      count > 1 ? 's' : ''
                    })`;
                  })
                  .value();

                return {
                  x: l,
                  y: getDegradationScore(l, attempts) || 0,
                  yLabel: levelPoints,
                  yLabelName: `${skill?.name} (${skill?.alias})`,
                  yType: 'solves',
                  yLevelPoints:
                    attempts?.filter((a) => a?.task?.difficulty === l)
                      ?.length || 0,
                  yLabelLastSolve: levelPoints ? lastSolveDateFormatted : 'N/A',
                  yContributors: levelPoints ? contributors : [],
                };
              }),
            ],
          };
        });
        return { ...specialty, flip, showHeader, levels, dataSorted };
      }) || [];

  const oddNumberSpecialties = specialtiesFormatted?.length % 2 !== 0;
  const specialtiesCount = specialtiesFormatted?.length || 0;
  const layoutSize =
    size === 'small'
      ? {
          brain: '510px',
          rightPadding: 47,
          leftPadding: 36,
        }
      : {
          brain: '580px',
          rightPadding: 38,
          leftPadding: 37,
        };

  return (
    <FdSkeleton
      loading={listSkillsLoading || listTaskAttemptAggregatesByUserIdLoading}
      height={665}
    >
      <Box id="cyber-brain" className="flex flex-col items-center">
        <Box
          className="grid grid-cols-2 gap-x-3"
          height="100%"
          width={layoutSize?.brain}
        >
          {specialtiesFormatted?.map((specialty, i) => (
            <Box
              style={{
                paddingRight: specialty?.flip && '1rem',
                position: 'relative',
                paddingBottom: 0,
              }}
            >
              <>
                <HeatMapCell
                  data={specialty?.dataSorted}
                  label={specialty?.specialtyName}
                  flip={specialty?.flip}
                  showHeader={specialty?.showHeader}
                  colors={{
                    type: 'sequential',
                    scheme: 'blues',
                    minValue: 0,
                    maxValue: 50,
                  }}
                  margin={{
                    top: SPECIALTY_SORT[specialty?.specialtyName]?.paddingTop,
                    right: specialty?.flip ? 2 : layoutSize?.rightPadding,
                    bottom: 0,
                    left: specialty?.flip ? layoutSize?.leftPadding : 2,
                  }}
                  showContributors
                />
                {specialty?.flip && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: [0, 1].includes(i) ? '35px' : 0,
                      right: 0,
                      bottom: oddNumberSpecialties
                        ? specialtiesCount - 1
                        : specialtiesCount - 2 === i
                        ? '24px'
                        : 0,
                      borderRight: '1px dashed #63A4FF',
                    }}
                  />
                )}
              </>
            </Box>
          ))}
        </Box>
      </Box>
    </FdSkeleton>
  );
};

CyberBrain.defaultProps = {
  timeLine: undefined,
  size: 'medium',
};

CyberBrain.propTypes = {
  allMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cortexMemberList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeLine: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  size: PropTypes.oneOf('small', 'medium'),
};

export default CyberBrain;
